<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <router-link :to="'shops' + '/'">
            <img
              v-if="market.ppl.productproducer.image_cropped"
              v-bind:src="market.ppl.productproducer.image_cropped"
              class="img-fluid"
            />
          </router-link>
        </CCol>
        <CCol md="12" class="text-center">
          <b>{{ market.ppl.productproducer.translations.it.name }}</b>
          <br />
          {{ market.ppl.productproducer.producer.role.name }}
          <br />
          <small>{{ market.ppl.translations.it.name }}</small>
          <br />
          <h5>{{ market.ppm.price }} CHF</h5>
          <br />
          <ShowcaseAddCart :ppm="market.ppm" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import ShowcaseAddCart from "./ShowcaseAddCart";

export default {
  name: "ShowcaseListBox",
  components: {
    ShowcaseAddCart,
  },
  data() {
    return {
      cur_lang: this.$i18n.locale,
      market: this.mm,
    };
  },
  model: {
    prop: "mm",
    event: "change",
  },
  props: ["mm"],

  watch: {
    mm: function () {
      this.market = this.mm;
    },
  },
};
</script>

<style scoped>
.product_name {
  font-size: 1.8em;
}
.product_disabled {
  font-size: 1.5em;
  text-decoration: line-through;
  color: grey;
}
</style>
